/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "../link/link"

const LearningLibraryListItem = ({
  title,
  slug,
  image,
  description,
}) => (
  <li>
    <Link to={`/learning-libraries/${slug}`} sx={{
      textDecoration: 'none',
      color: 'text',
      '&:hover': {
        color: 'text',
        '.gatsby-image-wrapper': {
          transform: 'translateY(0)',
        }
      }
    }}>
      <div
        sx={{
          position: 'relative',
          pb: '2rem',
          mb: '3rem',
          display: 'flex',
          justifyContent: 'center',
          '&:after': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: 0,
            height: '2rem',
            width: '100%',
            background: 'radial-gradient(ellipse at center center, rgba(0, 0, 0, 0.25) 0px, rgba(255, 255, 255, 0) 45%)',
          }
        }}
      >
        <GatsbyImage
          image={image}
          alt={title}
          sx={{ 
            maxWidth: '70%',
            transform: 'translateY(1rem)',
            transition: 'transform 0.25s',
          }}
        />
      </div>
      <Themed.p>{description}</Themed.p>
    </Link>
  </li>
)

LearningLibraryListItem.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default LearningLibraryListItem
