/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Panel from "../panel/panel"

const FullBleedQuote = ({
  images,
  alt,
  quote,
  attribution,
  quoteImage,
  alignment = "left",
}) => (
  <Panel sx={{ p: "0 !important" }}>
    <div sx={{ gridColumn: "1 / -1", gridRow: 1, zIndex: 0 }}>
      <GatsbyImage
        image={images}
        alt={alt}
        sx={{
          aspectRatio: ["0.8856427379", "auto"],
          height: "100%",
        }}
      />
    </div>
    <div
      sx={{
        gridColumn: "1 / -1",
        gridRow: 1,
        zIndex: 0,
        backgroundImage: [
          "linear-gradient(to bottom, rgba(25, 92, 193, 0) 0%, #195cc1 100%)",
          `linear-gradient(to ${alignment}, rgba(25, 92, 193, 0) 0%, #195cc1 100%)`,
        ],
        display: "flex",
        alignItems: ["flex-end", "center"],
        justifyContent: [
          null,
          alignment === "right" ? "flex-end" : "flex-start",
        ],
      }}
    >
      <figure
        sx={{
          color: "#fff",
          p: "3rem",
          px: ["3rem", "6rem", "8rem"],
          m: 0,
          textAlign: ["center", alignment],
          display: "flex",
          flexDirection: "column",
          alignItems: [null, alignment === "right" ? "end" : "start"],
          gap: ["2rem", null, "6rem"],
          width: [null, "60%"],
        }}
      >
        {quoteImage && (
          <GatsbyImage
            image={quoteImage}
            alt={alt}
            sx={{ maxWidth: '20rem', mb: '3rem' }}
          />
        )}
        <blockquote
          sx={{
            m: 0,
            "&:before": {
              content: '"“"',
            },
            "&:after": {
              content: '"”"',
            },
            "--min-font-size": [1.8, 1.8, 2.4],
            "--max-font-size": [1.8, 2.4, 4],
            lineHeight: [1.5, null, 1.35],
            fontWeight: [null, 600],
          }}
          className="scaleText"
        >
          {quote}
        </blockquote>
        <figcaption
          sx={{
            "--min-font-size": [0.9, 0.9, 1.4],
            "--max-font-size": [0.9, 1.4, 1.6],
            lineHeight: [1.56, null, 1.38],
          }}
          className="scaleText"
        >
          <div
            sx={{
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {attribution.name}
          </div>
          <div
            sx={{
              fontWeight: 300,
              textTransform: ["uppercase", "none"],
            }}
          >
            {attribution.title}
          </div>
        </figcaption>
      </figure>
    </div>
  </Panel>
)

FullBleedQuote.propTypes = {
  images: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
  attribution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  quoteImage: PropTypes.object,
}

export default FullBleedQuote
